<template>
  <div :class="{fullscreen:fullscreen}" class="tinymce-container" :style="{width:containerWidth}">
    <textarea :id="tinymceId" class="tinymce-textarea" />

    <!-- 上传图片input -->
    <input v-show="false" ref="file" type="file" accept="image/*" @change="handleUploadImage" />
  </div>
</template>

<script>
import load from './dynamicLoadScript'
import { Toast } from 'mint-ui'
import { uploadToOSS } from '@/request/upload'
const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'

export default {
  name: 'Tinymce',

  props: {
    id: {
      type: String,
      default: function () {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    }
  },

  data () {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      defaultToolbar: ['bold italic alignleft aligncenter alignright removeformat bullist numlist forecolor backcolor', 'upload fullscreen'],
      defaultPlugins: ['lists textcolor wordcount fullscreen'],
      languageTypeList: {
        en: 'en',
        zh: 'zh_CN',
        es: 'es_MX',
        ja: 'ja'
      }
    }
  },

  computed: {
    containerWidth () {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) { // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    }
  },

  watch: {
    value (val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    }
  },

  mounted () {
    this.init()
  },

  activated () {
    if (window.tinymce) {
      this.initTinymce()
    }
  },

  deactivated () {
    this.destroyTinymce()
  },

  destroyed () {
    this.destroyTinymce()
  },

  methods: {
    init () {
      // dynamic load tinymce from cdn
      load(tinymceCDN, (err) => {
        if (err) {
          Toast(err.message)
          return
        }
        this.initTinymce()
      })
    },
    initTinymce () {
      const _this = this
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        language: this.languageTypeList.zh,
        height: this.height,
        body_class: 'panel-body',
        branding: false,
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : this.defaultToolbar,
        menubar: false,
        plugins: this.defaultPlugins,
        end_container_on_empty_block: true,
        init_instance_callback: editor => {
          if (_this.value) {
            editor.setContent(_this.value)
          }
          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
          })
        },
        setup (editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
          editor.addButton('upload', {
            icon: 'image',
            onClick: () => {
              _this.handleSelectImage()
            }
          })
        },
        convert_urls: false
      })
    },
    // 销毁Tinymce对象
    destroyTinymce () {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent (value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent () {
      window.tinymce.get(this.tinymceId).getContent()
    },
    // 选择图片
    handleSelectImage () {
      this.$refs.file.click()
    },
    // 上传图片
    async handleUploadImage () {
      if (this.$refs.file.files[0]) {
        const file = this.$refs.file.files[0]
        const res = await this.api.getAliOSSToken()
        await uploadToOSS(res.data, file).then(res => {
          window.tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" style="max-width: 100%" src="${res}" />`)
        }).catch(() => {
          Toast('上传失败')
        })
      }
    }
  }
}
</script>

<style scoped>
.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}
.tinymce-container {
  position: relative;
  line-height: normal;
}
.tinymce-container >>> .mce-toolbar-grp {
  overflow-y: auto;
}
.tinymce-container >>> .mce-fullscreen {
  z-index: 10000;
}
</style>
