<template>
  <div>
    <!-- 表单 -->
    <div class="form">
      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label flex-row-default-center">
          <span class="iconfont icon-required"></span>
          <span>加入时间</span>
        </div>
        <input type="text" disabled :value="memberInfo.confirmedAt || memberInfo.createdAt" />
      </div>

      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label flex-row-default-center">
          <span class="iconfont icon-required"></span>
          <span>公司名</span>
        </div>
        <input type="text" disabled :value="memberInfo.companyName" />
      </div>

      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label flex-row-default-center">
          <span class="iconfont icon-required"></span>
          <span>统一信用代码</span>
        </div>
        <input type="text" disabled :value="memberInfo.usci" />
      </div>
    </div>

    <!-- 卡片式表单 -->
    <div class="card">

      <!-- 表单项 -->
      <div class="card-item">
        <div class="label">企业LOGO</div>
        <!-- 图片块 -->
        <div class="image-block flex-row-default-center">
          <div v-if="formData.logo !== ''" class="show-image">
            <img class="image" :src="formData.logo" />
          </div>
          <!-- 选择图片上传按钮 -->
          <div class="add-image flex-center-center" @click="handleSelectImage">+</div>
        </div>
      </div>

      <!-- 表单项 -->
      <div class="card-item">
        <div class="label">企业信息</div>
        <tinymce v-model="formData.content" :height="300" />
      </div>
    </div>

    <!-- 修改按钮 -->
    <div class="button-one" @click="handleUpdate">修 改</div>

    <!-- 上传图片input -->
    <input v-show="false" ref="file" type="file" accept="image/*" @change="handleUploadImage" />
  </div>
</template>

<script>
import { uploadToOSS } from '@/request/upload'
import { Toast } from 'mint-ui'
import Tinymce from '@/components/Tinymce'

export default {
  name: 'MemberInfo',

  data () {
    return {
      memberInfo: {},
      formData: {
        content: '',
        logo: ''
      }
    }
  },

  components: { Tinymce },

  created () {
    this.getData()
  },

  methods: {
    // 获取数据
    getData () {
      this.api.getMember().then(res => {
        this.memberInfo = res.data
        this.formData.content = res.data.content
        this.formData.logo = res.data.logo
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getData() })
      })
    },
    // 修改
    handleUpdate () {
      this.api.updateMemberInfo(this.formData).then(res => {
        Toast(res.msg)
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleUpdate() })
      })
    },
    // 选择图片
    handleSelectImage () {
      this.$refs.file.click()
    },
    // 上传图片
    async handleUploadImage () {
      if (this.$refs.file.files[0]) {
        const file = this.$refs.file.files[0]
        const res = await this.api.getAliOSSToken()
        await uploadToOSS(res.data, file).then(res => {
          this.formData.logo = res
        }).catch(() => {
          Toast('上传失败')
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
